<!--
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/aspect-ratio'),
    ],
  }
  ```
-->
<div class="bg-#91b8ce">
  <div class="max-w-2xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8">
    <h2 class="sr-only">Projects</h2>

    <button>
    <div class="grid grid-cols-1 gap-y-10 sm:grid-cols-2 gap-x-6 lg:grid-cols-3">
      <a (click)="showModal(project.name)" class="group" *ngFor="let project of projects">
        <div class="w-full aspect-w-1 aspect-h-1 bg-gray-200 rounded-lg overflow-hidden xl:aspect-w-7 xl:aspect-h-8">
          <img src="{{project.image}}" alt="Placeholder" class="w-full h-full object-center object-cover group-hover:opacity-75">
        </div>
        <h3 class="title mt-4 text-sm text-#253b49">{{project.name}}</h3>
        <p class="mt-1 text-lg font-medium text-#253b49">{{project.description}}</p>
      </a>
    </div>
    </button>
    
  </div>
</div>
<app-projectmodal [currentProject]="currentProject" [projects]="projects" [modal]="modal" (hideModal)="modal=$event" *ngIf="modal"></app-projectmodal>

