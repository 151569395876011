<!-- This example requires Tailwind CSS v2.0+ -->
<div class="bg-#91b8ce py-12">
  <div class="mx-auto max-w-fit px-4 sm:px-6 lg:px-8 grid sm:grid-cols-2 lg:grid-cols-9">

    <div class="mt-10" *ngFor="let skill of topSkills">
      <dl class="space-y-10 sm:grid sm:grid-cols-2 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10 md:space-y-0">
        <div class="relative">
          <dt>
            <img src="{{skill.logo}}" width=150 height=150/>
            <!-- <p class="ml-16 text-lg font-medium leading-6 text-#253b49">{{skill.logo}}</p> -->
          </dt>
        </div>
      </dl>
    </div>
  </div>
</div>


