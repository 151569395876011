<!-- This example requires Tailwind CSS v2.0+ -->
<div class="relative overflow-hidden">
  <div class="pt-20 pb-80 sm:pt-40 sm:pb-40 lg:pt-30 lg:pb-48">
    <div class="relative mx-auto max-w-7xl px-4 sm:static sm:px-6 lg:px-8">
      <div class="sm:max-w-lg">
        <h1 class="name font text-4xl font-bold tracking-tight sm:text-6xl">Braeden Ford</h1>
        <p class="mt-4 text-xl">I'm a full-stack developer with experience building applications from the ground up. I've delved into different front-end frameworks and worked with various libraries suited to give the best possible user experience. I've worked extensively on three applications, which you can view below. Please feel free to contact me for any more information.</p>
      </div>
      <a class="w-full inline-flex justify-center bg-[#064663] rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white hover:bg-[#ECB365] hover:text-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800 sm:ml-3 sm:w-auto sm:text-sm" href="../../assets/Braeden Ford - Resume.pdf" download>
        <button>Download Resume</button>
      </a>
      <div>
        <div class="mt-10">
          <!-- Decorative image grid -->
          <div aria-hidden="true" class="pointer-events-none lg:absolute lg:inset-y-0 lg:mx-auto lg:w-full lg:max-w-7xl">
            <div class="absolute transform sm:left-1/2 sm:top-0 sm:translate-x-8 lg:left-1/2 lg:top-1/2 lg:-translate-y-1/2 lg:translate-x-8">
              <div class="flex items-center">
                <div class="">
                  <div class="h-108 w-90 overflow-hidden rounded-lg sm:opacity-0 lg:opacity-100">
                    <img src="../assets/img/headshot.jpg" alt="" class="h-full w-full object-cover object-center">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

