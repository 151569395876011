<div class="fcf-body">

  <div id="fcf-form">
  <h3 class="fcf-h3">Contact here or at bford0002@gmail.com</h3>

  <form id="fcf-form-id" class="fcf-form-class" action='https://mailthis.to/bford0002' method="post">
      
      <div class="fcf-form-group">
          <label for="Name" class="fcf-label">Name</label>
          <div class="fcf-input-group">
              <input type="text" id="Name" name="Name" class="fcf-form-control" required>
          </div>
      </div>

      <div class="fcf-form-group">
          <label for="Email" class="fcf-label">Email Address</label>
          <div class="fcf-input-group">
              <input type="email" id="Email" name="Email" class="fcf-form-control" required>
          </div>
      </div>

      <div class="fcf-form-group">
          <label for="Message" class="fcf-label">Message</label>
          <div class="fcf-input-group">
              <textarea id="Message" name="Message" class="fcf-form-control" rows="6" maxlength="3000" required></textarea>
          </div>
      </div>

      <div class="fcf-form-group">
          <button type="submit" id="fcf-button" class="fcf-btn-lg fcf-btn-block w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-[#064663] text-base font-medium text-white hover:bg-[#ECB365] hover:text-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800 sm:ml-3 sm:w-auto sm:text-sm">Send</button>
      </div>
  </form>
  </div>

</div>
